<template>
  <a-modal
    ref="sectionModal"
    :title="readOnly ? '详情' : form.phaseId ? '阶段任务编辑' : '新增阶段任务'"
    :width="640"
    :visible="visible"
    @cancel="close"
    @ok="confirm"
    :confirmLoading="confirmLoading"
    :okButtonProps="{
      props: {
        disabled: readOnly ? true : false,
      },
    }"
  >
    <a-spin :spinning="loading">
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="名称" prop="sectionName">
              <a-input
                v-model="form.sectionName"
                placeholder="请输入阶段任务名称"
                :disabled="readOnly ? true : false"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24" :pull="3">
            <a-form-model-item label="序号" prop="orderNum">
              <a-input-number
                v-model="form.orderNum"
                placeholder="排序号"
                :disabled="readOnly ? true : false"
              />(建议以10为间隔，方便以后调整顺序)
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24" :pull="3">
            <a-form-model-item label="封面">
              <a-upload-dragger
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                :before-upload="beforeUpload"
                :headers="{ Authorization: token }"
                @change="handleChange"
                name="file"
                :withCredentials="true"
                :action="rongyiConfig.apiDomain + '/api/file/upload'"
              >
                <img
                  style="width: 80%; height: 80%"
                  v-if="this.form.coverUrl"
                  :src="rongyiConfig.staticResourceDomain + this.form.coverUrl"
                  alt="avatar"
                />
                <div v-else>
                  <a-icon :type="'plus'" />
                  <div class="ant-upload-text">上传封面</div>
                </div>
              </a-upload-dragger>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24" :pull="3">
            <a-form-model-item label="岗位能力">
              <a-textarea placeholder="请填写岗位能力" :maxLength="500" v-model="form.postAbility" :rows="4" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24" :pull="3">
            <a-form-model-item label="技术能力">
              <a-textarea placeholder="请填写技术能力" :maxLength="500" v-model="form.technicalAbility" :rows="4" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-spin>
  </a-modal>
</template>

<script>
import { addSection, updateSection } from '@/api/rongyi/workflow/section'

// 表单字段
export default {
  data() {
    return {
      confirmLoading: false,
      readOnly: false,
      visible: false,
      loading: false,
      // form: this.$form.createForm(this),
      // value: undefined,
      labelCol: {
        xs: { span: 12 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
      // 表单属性:
      form: {
        coverUrl: '',
        technicalAbility: '',
        postAbility: '',
        orderNum: '',
        sectionName: '',
      },
      // 表单校验
      rules: {
        sectionName: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
        orderNum: [{ required: true, message: '排序号不能为空', trigger: 'blur' }],
      },
      token: '',
      fileList: [],
    }
  },
  created() {
    const tk = localStorage.getItem('token')
    if (tk) {
      this.token = 'Bearer ' + tk
    }
  },
  methods: {
    beforeUpload(file, fileList) {
      // eslint-disable-next-line promise/param-names
      return new Promise((res, rej) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
        if (!isJpgOrPng) {
          this.$message.error('只能上传图片!')
        }
        this.fileList = [...this.fileList, file]
        this.fileList = this.fileList.slice(-1)
        if (isJpgOrPng) {
          res()
        } else {
          // eslint-disable-next-line prefer-promise-reject-errors
          rej()
        }
      })
    },
    handleChange({ file, fileList }) {
      const status = file.status
      if (status !== 'uploading') {
        console.log(file, fileList)
      }
      if (status === 'done') {
        this.form.coverUrl = []
        for (let i = 0; i < fileList.length; i++) {
          this.form.coverUrl = fileList[i].response.data.url
        }
      } else if (status === 'error') {
        this.$message.error(`${file.name} 上传失败!`)
      }
    },
    // 由于要用传进来的值做判断,将显示和隐藏放在内部做处理
    show(data, readOnly, phaseId) {
      // this.form.gradeId = parentId;
      if (data) {
        // 修改行为
        this.form = Object.assign({}, data) || {}
      } else {
        // 新增行为
        this.reset()
        this.form.phaseId = phaseId
      }
      this.readOnly = typeof readOnly !== 'undefined' ? readOnly === true : false
      this.visible = true
    },
    // 关闭模态框
    close() {
      this.visible = false
      this.reset()
    },
    confirm() {
      console.log('form')
      console.log(this.form)
      this.confirmLoading = true
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          console.log('valid success')
          console.log(this.form)
          // 进行新增行为:
          if (!this.isEmptyStr(this.form.sectionId)) {
            // 刷新表格
            updateSection(this.form).then((response) => {
              if (response.code === 200) {
                this.$message.success('修改成功')
                // 关闭本组件
                this.visible = false
                // 调用外部刷新列表方法
                this.$emit('handle-success')
                // 刷新表单
                this.reset()
                this.confirmLoading = false
              } else {
                this.$message.error(response.msg)
                this.confirmLoading = false
              }
            })
          } else {
            // 新增
            addSection(this.form).then((response) => {
              if (response.code === 200) {
                this.$message.success('新增成功')
                // 关闭本组件
                this.visible = false
                // 调用外部刷新列表方法
                this.$emit('handle-success')
                // 刷新表单
                this.reset()
                this.confirmLoading = false
              } else {
                this.$message.error(response.msg)
                this.confirmLoading = false
              }
            })
          }
        } else {
          console.log('valid false')
          console.log(this.form)
          return (this.confirmLoading = false)
        }
      })
    },
    // 表单重置
    reset() {
      this.form = {}
    },
    filterStrArrary(list) {
      for (let index = 0; index < list.length; index++) {
        if (list[index].children === '') {
          list[index].children = []
        }
        if (list[index].children) {
          this.filterStrArrary(list[index].children)
        }
      }
    },
  },
}
</script>
<style scoped>
.avatar {
  width: 128px;
  height: 128px;
}
.avatar-uploader {
  width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
