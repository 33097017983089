import request from '@/utils/request'

// 查询岗位列表
export function listSection (query) {
  return request({
    url: '/rongyi/workflowSection/list',
    method: 'get',
    params: query
  })
}

// 查询岗位详细
export function getSection (sectionId) {
  return request({
    url: '/rongyi/WorkflowSection/' + sectionId,
    method: 'get'
  })
}

// 新增岗位
export function addSection (data) {console.log("post data");console.log(data);
  return request({
    url: '/rongyi/workflowSection',
    method: 'post',
    data: data
  })
}

// 修改岗位
export function updateSection (data) {
  return request({
    url: '/rongyi/workflowSection',
    method: 'put',
    data: data
  })
}

// 删除岗位
export function delSection (sectionId) {
  return request({
    url: '/rongyi/workflowSection/' + sectionId,
    method: 'delete'
  })
}

//修改岗位状态
export function changeSectionStatus (sectionId, status) {
  const data = {
    sectionId:sectionId,
    status:status
  }
  return request({
    url: '/rongyi/workflowSection',
    method: 'put',
    data: data
  })
}