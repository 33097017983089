<template>
    <div class="hj-layout">
        <div class="hj-container">
            <div class="hj-layout_top">
                <div class="hj-titleBox">
                    <img
                        src="@/assets/img/hj/leftArrow.png"
                        style="margin-bottom: 5px"
                        @click="goBack"
                    />
                    <a class="hj-backButton" @click="goBack">返回</a>
                    <span class="hj-title">项目看板1</span>
                </div>
            </div>

            <div class="hj-layout_body">
                <div class="hj-tableContainer">
                    <template>
                        <a-spin :spinning="loading">
                        <a-tabs type="card" @change="tabChangeHandle">
                            <a-tab-pane v-for="(job,index) in jobList" :key="index"
                                :tab="job.jobName"
                                style="background-color: #f2f2f2;overflow-x:auto;"
                            >
                                <div class="main-box">
                                    <div class="lane" v-for="(phase,i) in job.phaseList" :key="i">
                                        <div class="phase">
                                            <div class="del" @click="delPhase(phase)">X</div>
                                            <span @click="updatePhase(phase)">{{phase.phaseName}}</span>
                                        </div>
                                        <div class="sect" v-for="(sect,j) in phase.sectionList" :key="j">
                                            <div class="del" @click="delSection(sect)">X</div>
                                            <span @click="updateSection(sect)">{{sect.sectionName}}</span>
                                        </div>
                                        <div class="sect" @click="addSection(phase)">
                                            <span>+点击添加阶段任务</span>
                                        </div>
                                    </div>
                                    <div class="lane">
                                        <div class="phase" @click="addPhase(job)">
                                            <span>+点击添加流程阶段</span>
                                        </div>

                                    </div>
                                </div>

                                <!-- <div class="hj-basicInfoBox" style="float:left" v-for="(process,i) in role.processes" :key="i">
                                    <div style="min-width: 100%">
                                        <div class="hj-lane" >
                                            <div class="hj-processName">
                                                <span>{{process.name}}</span>
                                            </div>
                                            <div class="hj-plus" @click="addTask(process)">
                                                <img style="margin:5px;"
                                                    src="@/assets/img/hj/plus.png"
                                                />
                                            </div>
                                            <div class="hj-task" v-for="(task,j) in process.tasks" :key="j" @click="handleTaskClick(task)">
                                                <a-checkbox
                                                    :checked="task.finished"
                                                    @change="onTaskChange(task)"
                                                >
                                                </a-checkbox>
                                                <span style="margin-left: 30px;height:40px;"
                                                    >{{task.name}}</span
                                                >
                                            </div>
                                        </div>
                                    </div>

                                    <div style="clear: both"></div>
                                </div> -->
                            </a-tab-pane>
                        </a-tabs>
                        </a-spin>
                    </template>
                    <phase-form ref="phaseModal" @handle-success="handlePhaseOk" />
                    <section-form ref="sectionModal" @handle-success="handleSectionOk" />
                    <div style="clear: both"></div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import PhaseForm from './modules/PhaseForm'
import SectionForm from './modules/SectionForm'
import { listJob } from '@/api/rongyi/workflow/job'
import { listPhase,delPhase } from '@/api/rongyi/workflow/phase'
import { listSection,delSection } from '@/api/rongyi/workflow/section'
export default {
    components: {
        PhaseForm,
        SectionForm,
    },
    data() {
        return {
            taskData:[
                {
                    role:"编导",
                    processes:[
                        {
                            name:"选题构思",
                            tasks:[
                                {
                                    name:"支付宝剪辑",
                                    finished:false,
                                },
                                {
                                    name:"快音剪辑",
                                    finished:false,
                                },
                                {
                                    name:"网易云音乐",
                                    finished:false,
                                },
                                {
                                    name:"阿里闪闪鱼",
                                    finished:false,
                                },
                                {
                                    name:"爱聊APP",
                                    finished:false,
                                }
                            ],
                            
                        },
                        {
                            name:"前期准备",
                            tasks:[
                                {
                                    name:"支付宝剪辑",
                                    finished:false,
                                },
                                {
                                    name:"快音剪辑",
                                    finished:false,
                                },
                                {
                                    name:"网易云音乐",
                                    finished:false,
                                },
                                {
                                    name:"阿里闪闪鱼",
                                    finished:false,
                                },
                                {
                                    name:"爱聊APP",
                                    finished:false,
                                }
                            ],
                            
                        },
                        {
                            name:"拍摄",
                            tasks:[
                                {
                                    name:"支付宝剪辑",
                                    finished:false,
                                },
                                {
                                    name:"快音剪辑",
                                    finished:false,
                                },
                                {
                                    name:"网易云音乐",
                                    finished:false,
                                },
                                {
                                    name:"阿里闪闪鱼",
                                    finished:false,
                                },
                                {
                                    name:"爱聊APP",
                                    finished:false,
                                }
                            ],
                            
                        },
                    ]
                },
                {
                    role:"制片",
                    processes:[
                        {
                            name:"选题构思",
                            tasks:[
                                {
                                    name:"支付宝剪辑",
                                    finished:false,
                                },
                                {
                                    name:"快音剪辑",
                                    finished:false,
                                },
                                {
                                    name:"网易云音乐",
                                    finished:false,
                                },
                                {
                                    name:"阿里闪闪鱼",
                                    finished:false,
                                },
                                {
                                    name:"爱聊APP",
                                    finished:false,
                                }
                            ],
                            
                        },
                        {
                            name:"前期筹备",
                            tasks:[
                                {
                                    name:"支付宝剪辑",
                                    finished:false,
                                },
                                {
                                    name:"快音剪辑",
                                    finished:false,
                                },
                                {
                                    name:"网易云音乐",
                                    finished:false,
                                },
                                {
                                    name:"阿里闪闪鱼",
                                    finished:false,
                                },
                                {
                                    name:"爱聊APP",
                                    finished:false,
                                }
                            ],
                            
                        },
                        {
                            name:"拍摄",
                            tasks:[
                                {
                                    name:"支付宝剪辑",
                                    finished:false,
                                },
                                {
                                    name:"快音剪辑",
                                    finished:false,
                                },
                                {
                                    name:"网易云音乐",
                                    finished:false,
                                },
                                {
                                    name:"阿里闪闪鱼",
                                    finished:false,
                                },
                                {
                                    name:"爱聊APP",
                                    finished:false,
                                }
                            ],
                            
                        },
                        {
                            name:"发行",
                            tasks:[
                                {
                                    name:"支付宝剪辑",
                                    finished:false,
                                },
                                {
                                    name:"快音剪辑",
                                    finished:false,
                                },
                                {
                                    name:"网易云音乐",
                                    finished:false,
                                },
                                {
                                    name:"阿里闪闪鱼",
                                    finished:false,
                                },
                                {
                                    name:"爱聊APP",
                                    finished:false,
                                }
                            ],
                            
                        },
                    ]
                },
                {
                    role:"演员",
                    processes:[
                        {
                            name:"读剧本",
                            tasks:[
                                {
                                    name:"支付宝剪辑",
                                    finished:false,
                                },
                                {
                                    name:"快音剪辑",
                                    finished:false,
                                },
                                {
                                    name:"网易云音乐",
                                    finished:false,
                                },
                                {
                                    name:"阿里闪闪鱼",
                                    finished:false,
                                },
                                {
                                    name:"爱聊APP",
                                    finished:false,
                                }
                            ],
                            
                        },
                        {
                            name:"角色体验",
                            tasks:[
                                {
                                    name:"支付宝剪辑",
                                    finished:false,
                                },
                                {
                                    name:"快音剪辑",
                                    finished:false,
                                },
                                {
                                    name:"网易云音乐",
                                    finished:false,
                                },
                                {
                                    name:"阿里闪闪鱼",
                                    finished:false,
                                },
                                {
                                    name:"爱聊APP",
                                    finished:false,
                                }
                            ],
                            
                        },
                        {
                            name:"交流沟通",
                            tasks:[
                                {
                                    name:"支付宝剪辑",
                                    finished:false,
                                },
                                {
                                    name:"快音剪辑",
                                    finished:false,
                                },
                                {
                                    name:"网易云音乐",
                                    finished:false,
                                },
                                {
                                    name:"阿里闪闪鱼",
                                    finished:false,
                                },
                                {
                                    name:"爱聊APP",
                                    finished:false,
                                }
                            ],
                            
                        },
                        {
                            name:"拍摄",
                            tasks:[
                                {
                                    name:"支付宝剪辑",
                                    finished:false,
                                },
                                {
                                    name:"快音剪辑",
                                    finished:false,
                                },
                                {
                                    name:"网易云音乐",
                                    finished:false,
                                },
                                {
                                    name:"阿里闪闪鱼",
                                    finished:false,
                                },
                                {
                                    name:"爱聊APP",
                                    finished:false,
                                }
                            ],
                            
                        },
                        {
                            name:"",
                            tasks:[
                            ],
                            
                        },
                    ]
                },
            ],
            jobList:[],
            loading:false,
            currentJobIndex:0,
        }
    },
    async created(){
        console.log('this.jobList',this.jobList)
        await this.getJobList()
        console.log('this.jobList',this.jobList)
        this.getData(this.currentJobIndex)
    },

    mounted() {
        let query = this.$route.query;
        console.log(query);
        this.projectName = query.name;
    },

    methods: {
        goBack() {
            this.$router.back(-1);
        },
        async getJobList(){
            let res = await listJob()
            if(res.code==200){
                this.jobList = res.rows
            }else{
                this.$message.error("获取数据失败!")
            }
            
        },
        getData(jobIndex){
            let that = this
            that.loading = true;
            let params = {
                jobId:that.jobList[jobIndex].jobId,
            }
            listPhase(params).then(res=>{
                if(res.code==200){
                    // that.jobList[jobIndex].phaseList = res.rows
                    // that.$set(that.jobList[jobIndex],'phaseList',res.rows)
                    let finishFlag = []
                    if(res.rows.length==0){
                        that.loading = false;
                    }
                    res.rows.sort((a,b)=>{return a.orderNum-b.orderNum})
                    for(let i=0;i<res.rows.length;i++){
                        // let phase = that.jobList[jobIndex].phaseList[i];
                        let params1 = {
                            phaseId:res.rows[i].phaseId
                        }
                        listSection(params1).then(res1=>{
                            if(res1.code==200){
                                res1.rows.sort((a,b)=>{return a.orderNum - b.orderNum})
                                res.rows[i].sectionList = res1.rows
                                // that.$set(that.jobList[jobIndex].phaseList[i],'sectionList',res1.rows)
                            }else{
                                // this.$message.error("获取数据失败!")
                            }
                            finishFlag.push(true)
                                console.log('finishFlag.length',finishFlag.length)
                                console.log('res1.rows.length',res.rows.length)
                            if(finishFlag.length == res.rows.length){
                                that.$set(that.jobList[jobIndex],'phaseList',res.rows)
                                console.log('jobList',that.jobList)
                                that.loading = false;
                            }
                        })
                    }
                }else{
                    this.$message.error("获取数据失败!")
                    that.loading = false;
                }

            })

        },
        tabChangeHandle(e){
            this.currentJobIndex = e;
            this.getData(this.currentJobIndex)
        },
        addPhase(job){
            this.$refs.phaseModal.show(null, false,job.jobId);
        },
        updatePhase(phase){
            this.$refs.phaseModal.show(phase, false);
        },
        addSection(phase){
            this.$refs.sectionModal.show(null, false,phase.phaseId);
        },
        updateSection(section){
            this.$refs.sectionModal.show(section, false);
        },
        delPhase(phase){
            const that = this
            this.$confirm({
                title: '警告',
                content: `真的要删除 名为${phase.phaseName}的流程阶段吗?`,
                okText: '删除',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    delPhase(phase.phaseId).then((response) => {
                        if (response.code === 200) {
                            that.$message.success('删除成功!')
                            that.getData(this.currentJobIndex)
                        } else {
                            that.$message.error(response.msg)
                        }
                    })
                },
            })
        },
        delSection(section){
            const that = this
            this.$confirm({
                title: '警告',
                content: `真的要删除名为 ${section.sectionName} 的流程阶段任务吗?`,
                okText: '删除',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    delSection(section.sectionId).then((response) => {
                        if (response.code === 200) {
                            that.$message.success('删除成功!')
                            that.getData(that.currentJobIndex)
                        } else {
                            that.$message.error(response.msg)
                        }
                    })
                },
            })
        },
        handlePhaseOk(){
            this.getData(this.currentJobIndex)
        },
        handleSectionOk(){
            this.getData(this.currentJobIndex)
        }
    },
};
</script>


<style lang="less">
.customButton {
    background-color: #dbeffa;
    color: #63b3fd;
    border: none;
}
.customButton:hover {
    background-color: #dbeffa;
    color: #63b3fd;
    border: solid 1px #63b3fd;
}
.customButton:visited {
    background-color: #dbeffa;
    color: #63b3fd;
    border: none;
}
.hj-row1 {
    background-color: #fff;
}
.hj-row2 {
    background-color: #edf2f9;
}
/deep/ .ant-table-thead > tr > th {
    white-space: nowrap !important; // 防止IE等浏览器不支持'max-content'属性 导致内容换行
    background: #f2f2f2 !important;
    // color: #000000;
    // border-bottom: 1px solid #e8e8e8;
}
a {
    color: #63b3fd;
}
a:hover {
    color: #63b3fd;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    color: #63b3fd;
}
.ant-tabs-nav .ant-tabs-tab:hover {
    color: #63b3fd;
}
.ant-tabs-nav-scroll {
    background-color: #f3f8fa;
}
</style>


<style lang="less" scoped>
.hj-layout {
    width: 100%;
    min-width: 1200px;
    display: flex;
    flex-direction: column;

    .hj-verticalBar {
        width: 5px;
        height: 18px;
        background-color: red;
        float: left;
        margin-right: 6px;
    }
    .hj-sectLabel {
        font-size: 16px;
        font-weight: bolder;
        line-height: 18px;
    }

    .hj-container {
        padding: 15px;
        background: #f3f8fa;
        .hj-layout_top {
            width: 100%;
            padding: 10px;
            .hj-title {
                position: relative;
                left: 35%;
                font-size: 20px;
                font-weight: bolder;
            }
            .hj-backButton {
                margin-left: 12px;
                bottom: 0;
                font-weight: 900;
                font-size: 18px;
                color: gray;
            }
        }
        .hj-layout_body {
            .hj-tableContainer {
                background-color: #fff;
                padding-bottom: 20px;
                position: relative;

                .hj-basicInfoBox {
                    padding: 15px;
                    padding-bottom: 30px;
                    height: auto;
                    .hj-baseInfo {
                        width: 100%;
                        font-size: 10px;
                        .hj-baseInfoCell {
                            padding: 20px 0;
                            float: left;
                            .hj-baseInfoLabel {
                                margin-right: 20px;
                            }
                        }
                        .cell15 {
                            width: 15%;
                        }
                        .cell20 {
                            width: 20%;
                        }
                        .cell25 {
                            width: 25%;
                        }
                        .cell30 {
                            width: 30%;
                        }
                        .cell60 {
                            width: 60%;
                        }
                        .cell80 {
                            width: 80%;
                        }
                        .cell100 {
                            width: 100%;
                        }
                    }
                }
                .hj-lane {
                    width: 200px;
                    padding: 10px;
                    .hj-processName {
                        width: 100%;
                        margin:10px;
                        font-size: 18px;
                        font-weight: bold;
                        min-height: 26px;
                    }
                    .hj-plus {
                        width: 100%;
                        border: solid 1px;
                        border-radius: 5px;
                        text-align: center;
                        background-color: #fff;
                        box-shadow: 3px 3px 3px #888;
                    }
                    .hj-task {
                        margin-top: 10px;
                        border: solid 1px ;
                        border-radius: 4px;
                        padding: 11px 25px;
                        background-color: #fff;
                        box-shadow:3px 3px 3px #888;
                    }
                }
                .hj-img1 {
                    margin-left: 30px;
                    margin-right: 15px;
                    width: 32px;
                    height: 32px;
                }
                .hj-exportButton {
                    position: absolute;
                    top: 1px;
                    right: 1px;
                }
            }
        }

        .hj-layout_dialog {
            .drawerOperation {
                padding: 0px 40px;
                flex: auto;
                display: flex;
                flex-direction: column;
                height: 100%;

                .drawerOperation_container {
                    flex: auto;
                }

                .drawerOperation_btnContaner {
                    flex: 0 0 60px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    border-top: 1px solid #eee;

                    > .btn {
                        margin-left: 20px;
                        width: 100px;
                    }
                }
            }

            
        }
    }
}

.main-box{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    min-width: 99%;
    // width:100%;
    width:fit-content;
    min-height:200px;
    margin:0 10px;
    background-color: #ffffff;
    border:solid 1px #f0f0f0;
    .lane{
        display: flex;
        flex-direction: column;
        margin:1px 10px;
        width:200px;

        .phase{                    
            margin-top: 10px;
            border: solid 1px ;
            border-radius: 4px;
            padding: 11px 25px;
            background-color: rgb(233, 233, 233);
            box-shadow:3px 3px 3px #888;
            position:relative;
            .del{
                border: solid 1px;
                width: 12px;
                height: 13px;
                top: 0px;
                left: 0px;
                line-height: 11px;
                position: absolute;
            }
        }

        .sect{           
            margin-top: 10px;
            border: solid 1px ;
            border-radius: 4px;
            padding: 11px 25px;
            background-color: rgb(255, 255, 255);
            box-shadow:3px 3px 3px #888;
            position:relative;
            .del{
                border: solid 1px;
                width: 12px;
                height: 13px;
                top: 0px;
                left: 0px;
                line-height: 11px;
                position: absolute;
            }
        }
    }
}
</style>
