import request from '@/utils/request'

// 查询岗位列表
export function listJob (query) {
  return request({
    url: '/rongyi/WorkflowJob/list',
    method: 'get',
    params: query
  })
}

// 查询岗位详细
export function getJob (jobId) {
  return request({
    url: '/rongyi/WorkflowJob/' + jobId,
    method: 'get'
  })
}

// 新增岗位
export function addJob (data) {console.log("post data");console.log(data);
  return request({
    url: '/rongyi/WorkflowJob',
    method: 'post',
    data: data
  })
}

// 修改岗位
export function updateJob (data) {
  return request({
    url: '/rongyi/WorkflowJob',
    method: 'put',
    data: data
  })
}

// 删除岗位
export function delJob (jobId) {
  return request({
    url: '/rongyi/WorkflowJob/' + jobId,
    method: 'delete'
  })
}

//修改岗位状态
export function changeJobStatus (jobId, status) {
  const data = {
    jobId:jobId,
    status:status
  }
  return request({
    url: '/rongyi/WorkflowJob',
    method: 'put',
    data: data
  })
}