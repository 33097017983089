import request from '@/utils/request'

// 查询岗位列表
export function listPhase (query) {
  return request({
    url: '/rongyi/workflowPhase/list',
    method: 'get',
    params: query
  })
}

// 查询岗位详细
export function getPhase (phaseId) {
  return request({
    url: '/rongyi/workflowPhase/' + phaseId,
    method: 'get'
  })
}

// 新增岗位
export function addPhase (data) {console.log("post data");console.log(data);
  return request({
    url: '/rongyi/workflowPhase',
    method: 'post',
    data: data
  })
}

// 修改岗位
export function updatePhase (data) {
  return request({
    url: '/rongyi/workflowPhase',
    method: 'put',
    data: data
  })
}

// 删除岗位
export function delPhase (phaseId) {
  return request({
    url: '/rongyi/workflowPhase/' + phaseId,
    method: 'delete'
  })
}

//修改岗位状态
export function changePhaseStatus (phaseId, status) {
  const data = {
    phaseId:phaseId,
    status:status
  }
  return request({
    url: '/rongyi/workflowPhase',
    method: 'put',
    data: data
  })
}