<template>
    <a-modal
        ref="phaseModal"
        :title="readOnly ? '详情' : form.phaseId ? '流程阶段编辑' : '新增流程阶段'"
        :width="640"
        :visible="visible"
        @cancel="close"
        @ok="confirm"
        :confirmLoading="confirmLoading"
        :okButtonProps="{
            props: {
                disabled: readOnly?true:false,
            },
        }"
    >
        <a-spin :spinning="loading">
            <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-row>
                    <a-col :span="12">
                        <a-form-model-item label="名称" prop="phaseName">
                            <a-input v-model="form.phaseName" placeholder="请输入阶段名称" :disabled="readOnly?true:false" />
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24" :pull="3">
                        <a-form-model-item label="序号" prop="orderNum">
                            <a-input-number v-model="form.orderNum" placeholder="排序号" :disabled="readOnly?true:false" />(建议以10为间隔，方便以后调整顺序)
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
        </a-spin>
    </a-modal>
</template>

<script>
import { addPhase, updatePhase } from '@/api/rongyi/workflow/phase'
// 表单字段
export default {
    data() {
        return {
            confirmLoading: false,
            readOnly: false,
            visible: false,
            loading: false,
            // form: this.$form.createForm(this),
            // value: undefined,
            labelCol: {
                xs: { span: 12 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
            // 表单属性:
            form: {},
            // 表单校验
            rules: {
                phaseName: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
                orderNum: [{ required: true, message: '排序号不能为空', trigger: 'blur' }],
            },
        }
    },
    created() {

    },
    methods: {
        // 由于要用传进来的值做判断,将显示和隐藏放在内部做处理
        show(data, readOnly,jobId) {
            // this.form.gradeId = parentId;
            if (data) {
                // 修改行为
                this.form = Object.assign({}, data) || {}
            } else {
                // 新增行为
                this.reset();
                this.form.jobId = jobId
            }
            this.readOnly = typeof readOnly !== 'undefined' ? readOnly === true : false
            this.visible = true
        },
        // 关闭模态框
        close() {
            this.visible = false
            this.reset()
        },
        confirm() {
            console.log('form')
            console.log(this.form)
            this.confirmLoading = true
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    console.log('valid success')
                    console.log(this.form)
                    // 进行新增行为:
                    if (!this.isEmptyStr(this.form.phaseId)){
                        // 刷新表格
                        updatePhase(this.form).then((response) => {
                            if (response.code === 200) {
                                this.$message.success('修改成功')
                                // 关闭本组件
                                this.visible = false
                                // 调用外部刷新列表方法
                                this.$emit('handle-success')
                                // 刷新表单
                                this.reset()
                                this.confirmLoading = false
                            } else {
                                this.$message.error(response.msg)
                                this.confirmLoading = false
                            }
                        })
                    } else {
                        // 新增
                        addPhase(this.form).then((response) => {
                            if (response.code === 200) {
                                this.$message.success('新增成功')
                                // 关闭本组件
                                this.visible = false
                                // 调用外部刷新列表方法
                                this.$emit('handle-success')
                                // 刷新表单
                                this.reset()
                                this.confirmLoading = false
                            } else {
                                this.$message.error(response.msg)
                                this.confirmLoading = false
                            }
                        })
                    }
                } else {
                    console.log('valid false')
                    console.log(this.form)
                    return (this.confirmLoading = false)
                }
            })
        },
        // 表单重置
        reset() {
            this.form = {}
        },
        filterStrArrary(list) {
            for (let index = 0; index < list.length; index++) {
                if (list[index].children === '') {
                    list[index].children = []
                }
                if (list[index].children) {
                    this.filterStrArrary(list[index].children)
                }
            }
        },
    },
}
</script>
<style scoped>
.avatar {
    width: 128px;
    height: 128px;
}
.avatar-uploader {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
}
.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}
</style>